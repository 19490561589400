import { CountryAlpha2, Currency, IANATimezone } from '@supy.api/dictionaries';

import { IdType } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import {
  AccountingCategoryApi,
  CreateAccountingCategoryRequest,
  CreateRecipeCategoryRequest,
  CreateWastageTypeRequest,
  InventoryCostingMethod,
  PushToInventoryMethod,
  RecipeCategoryApi,
  SalesType,
  SettingsApi,
  UpdateAccountingCategoryRequest,
  UpdateRecipeCategoryRequest,
  UpdateSettingsRequest,
  UpdateWastageTypeRequest,
  WastageTypeApi,
} from './settings.model';

// TODO: refactor this to proper general settings and settings under unleash
export interface GeneralSettings {
  readonly country: SettingsCountry;
  readonly notificationEmails?: string[];
}

export interface CostingMethodSettings {
  readonly costingMethod: InventoryCostingMethod;
  readonly isNegative: boolean;
}

export interface SettingsCountry {
  readonly id: string;
  readonly code: CountryAlpha2;
  readonly name: string;
  readonly currency: Currency;
  readonly utcOffset: number;
  readonly ianaTimeZone: IANATimezone;
  readonly currencyPrecision: number;
}

export interface PushToInventorySettings {
  readonly method: PushToInventoryMethod;
  readonly isQuantityModificationAllowed: boolean;
  readonly isPriceModificationAllowed: boolean;
  readonly displayStockCount: boolean;
  readonly quantity: number;
  readonly price: number;
  readonly preventBackdatedUpdates: boolean;
  readonly preventOrderingOutOfStock: boolean;
  readonly hideCKStock: boolean;
  readonly allowCkInvoice: boolean;
  readonly hideCKCost: boolean;
}

export class Settings {
  private constructor(args: NonFunctionProperties<Settings>) {
    this.id = args.id;
    this.generalSettings = args.generalSettings;
    this.wastageTypes = args.wastageTypes;
    this.costingMethodSettings = args.costingMethodSettings;
    this.pushToInventorySettings = args.pushToInventorySettings;
    this.recipeCategories = args.recipeCategories;
    this.accountingCategories = args.accountingCategories;
    this.manageChannelItemsPerLocation = args.manageChannelItemsPerLocation;
    this.salesTypes = args.salesTypes;
    this.mandatoryAccountingCategoryOnItem = args.mandatoryAccountingCategoryOnItem;
  }

  readonly id: string;
  readonly generalSettings: GeneralSettings;
  readonly wastageTypes: WastageType[];
  readonly costingMethodSettings: CostingMethodSettings;
  readonly pushToInventorySettings: PushToInventorySettings;
  readonly recipeCategories: RecipeCategory[];
  readonly accountingCategories: AccountingCategory[];
  readonly manageChannelItemsPerLocation: boolean;
  readonly salesTypes: SalesType[];
  readonly mandatoryAccountingCategoryOnItem: boolean;

  static deserialize(data: SettingsApi): Settings {
    return new Settings({
      id: data.id,
      generalSettings: {
        country: data.country,
        notificationEmails: data.notificationEmails,
      },
      wastageTypes: WastageType.deserializeList(data.wastages ?? []),
      costingMethodSettings: {
        costingMethod: data.inventoryCosting,
        isNegative: data.allowNegativeQuantity.enabled,
      },
      pushToInventorySettings: {
        method: data.automaticInventoryPush.enabled ? PushToInventoryMethod.Auto : PushToInventoryMethod.Manual,
        isPriceModificationAllowed: data.allowModifyReceivingPrice.enabled,
        isQuantityModificationAllowed: data.allowModifyReceivingQuantity.enabled,
        price: data.allowModifyReceivingPrice.data?.value ?? 0,
        quantity: data.allowModifyReceivingQuantity.data?.value ?? 0,
        displayStockCount: data.displayStockCount?.enabled ?? false,
        preventBackdatedUpdates: data.preventBackdatedUpdates?.enabled ?? false,
        preventOrderingOutOfStock: data.preventOrderingOutOfStock?.enabled ?? false,
        hideCKStock: data.hideCKStock?.enabled ?? false,
        allowCkInvoice: data.allowCKInvoice?.enabled ?? false,
        hideCKCost: data.hideCKCost?.enabled ?? false,
      },
      recipeCategories: RecipeCategory.deserializeList(data.recipeCategories ?? []),
      manageChannelItemsPerLocation: data.manageChannelItemsPerLocation.enabled,
      accountingCategories: AccountingCategory.deserializeList(data.accountingCategories ?? []),
      mandatoryAccountingCategoryOnItem: data.mandatoryAccountingCategoryOnItem?.enabled ?? false,
      salesTypes: (data.salesTypes ?? []).map(salesType => ({
        id: salesType.id,
        code: salesType.code,
        name: salesType.name,
        isDefault: salesType.isDefault,
      })),
    });
  }

  static serialize(data: Settings): UpdateSettingsRequest {
    return {
      id: data.id,
      notificationEmails: data.generalSettings.notificationEmails,
      allowModifyReceivingPrice: {
        enabled: data.pushToInventorySettings.isPriceModificationAllowed,
        data: { value: data.pushToInventorySettings.price },
      },
      allowModifyReceivingQuantity: {
        enabled: data.pushToInventorySettings.isQuantityModificationAllowed,
        data: { value: data.pushToInventorySettings.quantity },
      },
      allowNegativeQuantity: { enabled: data.costingMethodSettings.isNegative },
      automaticInventoryPush: { enabled: data.pushToInventorySettings.method === PushToInventoryMethod.Auto },
      displayStockCount: { enabled: data.pushToInventorySettings.displayStockCount },
      inventoryCosting: data.costingMethodSettings.costingMethod,
      preventBackdatedUpdates: { enabled: data.pushToInventorySettings.preventBackdatedUpdates },
      preventOrderingOutOfStock: { enabled: data.pushToInventorySettings.preventOrderingOutOfStock },
      hideCKStock: { enabled: data.pushToInventorySettings.hideCKStock },
      allowCKInvoice: { enabled: data.pushToInventorySettings.allowCkInvoice },
      mandatoryAccountingCategoryOnItem: { enabled: data.mandatoryAccountingCategoryOnItem },
      hideCKCost: { enabled: data.pushToInventorySettings.hideCKCost },
    };
  }
}

export class WastageType {
  private constructor(args: NonFunctionProperties<WastageType>) {
    this.id = args.id;
    this.name = args.name;
    this.limit = args.limit;
    this.isActive = args.isActive;
    this.expense = args.expense;
    this.isSalesWastage = args.isSalesWastage;
  }

  readonly id: string;
  readonly name: string;
  readonly expense: boolean;
  readonly limit: number;
  readonly isActive: boolean;
  readonly isSalesWastage: boolean;

  static deserialize(data: WastageTypeApi): WastageType {
    return new WastageType({
      id: data.id,
      name: data.name,
      limit: data.limit,
      isActive: data.isActive,
      expense: data.expense,
      isSalesWastage: data.isSalesWastage,
    });
  }

  static deserializeList(data: WastageTypeApi[]): WastageType[] {
    return data.map(wastageType => WastageType.deserialize(wastageType));
  }

  static serializeCreate(data: WastageType, retailerId: string): CreateWastageTypeRequest {
    return {
      name: data.name,
      expense: data.expense,
      isActive: data.isActive,
      limit: data.limit,
      retailer: { id: retailerId },
    };
  }

  static serializeUpdate(data: WastageType, retailerId: string): UpdateWastageTypeRequest {
    return {
      id: data.id,
      ...this.serializeCreate(data, retailerId),
      isSalesWastage: data.isSalesWastage,
    };
  }

  static default(): WastageType {
    return { id: crypto.randomUUID(), name: '', expense: false, limit: 0, isActive: true, isSalesWastage: false };
  }
}

export class RecipeCategory {
  private constructor(args: NonFunctionProperties<RecipeCategory>) {
    this.id = args.id;
    this.name = args.name;
    this.isActive = args.isActive;
    this.parent = args.parent;
    this.children = args.children;
  }

  readonly id: string;
  readonly name: string;
  readonly isActive: boolean;
  readonly parent: IdType | null;
  readonly children?: RecipeCategory[];

  static deserialize(data: RecipeCategoryApi): RecipeCategory {
    return new RecipeCategory({
      id: data.id,
      name: data.name,
      isActive: data.isActive,
      parent: data.parent,
      children: data.children,
    });
  }

  static deserializeList(data: RecipeCategoryApi[]): RecipeCategory[] {
    return data.map(recipeCategory => RecipeCategory.deserialize(recipeCategory));
  }

  static serializeCreate(
    data: Pick<RecipeCategory, 'name' | 'isActive'>,
    retailerId: string,
  ): CreateRecipeCategoryRequest {
    return {
      name: data.name,
      isActive: data.isActive,
      retailer: { id: retailerId },
    };
  }

  static serializeUpdate(
    data: Pick<RecipeCategory, 'id' | 'name' | 'isActive'>,
    retailerId: string,
  ): UpdateRecipeCategoryRequest {
    return {
      id: data.id,
      ...this.serializeCreate(data, retailerId),
    };
  }

  static default(): RecipeCategory {
    return {
      id: '',
      name: '',
      children: [],
      isActive: true,
      parent: null,
    };
  }
}

export class AccountingCategory {
  private constructor(args: NonFunctionProperties<AccountingCategory>) {
    this.id = args.id;
    this.name = args.name;
    this.isActive = args.isActive;
  }

  readonly id: string;
  readonly name: string;
  readonly isActive: boolean;

  static default(): AccountingCategory {
    return { id: '', name: '', isActive: true };
  }

  static deserialize(data: AccountingCategoryApi): AccountingCategory {
    return new AccountingCategory({
      id: data.id,
      name: data.name,
      isActive: data.isActive,
    });
  }

  static deserializeList(data: AccountingCategoryApi[]): AccountingCategory[] {
    return data.map(accountingCategory => AccountingCategory.deserialize(accountingCategory));
  }

  static serializeCreate(data: AccountingCategory, retailerId: string): CreateAccountingCategoryRequest {
    return {
      name: data.name,
      isActive: data.isActive,
      retailer: { id: retailerId },
    };
  }

  static serializeUpdate(data: AccountingCategory, retailerId: string): UpdateAccountingCategoryRequest {
    return {
      id: data.id,
      ...this.serializeCreate(data, retailerId),
    };
  }
}
