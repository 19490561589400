import { ModuleWithProviders, NgModule } from '@angular/core';

import { SDK_OPTIONS } from './common';
import { OpenTelemetryModuleOptions } from './config';
import { MetricService, NoopMetricService, OpenTelemetryMetricModule } from './metrics';
import { NoopOpenTelemetryService, OpenTelemetryService } from './opentelemetry.service';
import { NoopTraceService, OpenTelemetryTracingModule, TraceService } from './tracing';

const modules = [OpenTelemetryMetricModule, OpenTelemetryTracingModule];

@NgModule({
  imports: modules,
  exports: modules,
})
export class OpenTelemetryModule {
  constructor(readonly openTelemetryService: OpenTelemetryService) {}

  static forRoot(options: OpenTelemetryModuleOptions): ModuleWithProviders<OpenTelemetryModule> {
    return {
      ngModule: OpenTelemetryModule,
      providers: [
        {
          provide: MetricService,
          useClass: options.metrics?.enabled ? MetricService : NoopMetricService,
        },
        {
          provide: TraceService,
          useClass: options.tracing?.enabled ? TraceService : NoopTraceService,
        },
        {
          provide: OpenTelemetryService,
          useClass: options.enabled ? OpenTelemetryService : NoopOpenTelemetryService,
        },
        {
          provide: SDK_OPTIONS,
          useValue: options,
        },
      ],
    };
  }
}
