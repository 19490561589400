import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Meter } from '@opentelemetry/api-metrics';
import { OTLPMetricExporter } from '@opentelemetry/exporter-metrics-otlp-http';
import { Resource } from '@opentelemetry/resources';
import {
  ConsoleMetricExporter,
  MeterProvider,
  MetricReader,
  PeriodicExportingMetricReader,
} from '@opentelemetry/sdk-metrics';
import { ATTR_SERVICE_NAME, ATTR_SERVICE_VERSION } from '@opentelemetry/semantic-conventions';

import { DEFAULT_METER, SDK_OPTIONS } from '../common';
import { OpenTelemetryModuleOptions } from '../config';
import { IMetricService } from './base';

@Injectable()
export class MetricService implements IMetricService, OnDestroy {
  private readonly meterProvider: MeterProvider;

  constructor(@Inject(SDK_OPTIONS) options: OpenTelemetryModuleOptions) {
    const metricReaders: MetricReader[] = [];

    if (options.metrics?.debug) {
      metricReaders.push(
        new PeriodicExportingMetricReader({
          exporter: new ConsoleMetricExporter(),
        }),
      );
    }

    metricReaders.push(
      new PeriodicExportingMetricReader({
        exporter: new OTLPMetricExporter({
          url: options.metrics?.url,
        }),
      }),
    );

    const meterProvider = new MeterProvider({
      resource: new Resource({
        [ATTR_SERVICE_NAME]: options.application.name,
        [ATTR_SERVICE_VERSION]: options.application.version,
      }),
      readers: metricReaders,
    });

    this.meterProvider = meterProvider;
  }

  getMeter(name: string = DEFAULT_METER): Meter {
    return this.meterProvider.getMeter(name);
  }

  getMeterProvider(): MeterProvider {
    return this.meterProvider;
  }

  async ngOnDestroy(): Promise<void> {
    await this.meterProvider.forceFlush();
    await this.meterProvider.shutdown();
  }
}
