export enum ToggleFeature {
  CentralKitchen = 'retailer.central-kitchen',
  CentralKitchenB2B = 'retailer.central-kitchen-b2b',
  Inventory = 'retailer.inventory',
  IntegratedItemManagement = 'retailer.integrated-item-management',
  OrderReceiving = 'retailer.order-receiving',
  ReferralProgram = 'admin.referral-program',
  AccountingIntegration = 'retailer.accounting-integration',
  AutoGRN = 'retailer.auto-grn',
  Localize = 'retailer.localize',
  ConsolidatedInvoice = 'retailer.consolidated-invoice',
}
