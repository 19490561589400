import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'supyPrecision',
})
export class PrecisionPipe implements PipeTransform {
  transform(value: string | number, precision = 3, thousandsWithComma = false): string {
    if (!value) {
      return Number(0).toFixed(precision);
    }

    const numberWithoutCommas = this.getNumberWithoutCommas(value);

    const numberWithPrecision = Number(numberWithoutCommas).toFixed(precision);

    return thousandsWithComma ? this.getNumberStringWithCommas(numberWithPrecision) : numberWithPrecision;
  }

  getNumberStringWithCommas(value: number | string): string {
    if (!value) {
      return;
    }

    return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  }

  /**
   * Implemented in case formatted value with thousand-commas is sent as input. Here `Number(value)` will result in NaN unless cleaned. Eg: 1,000.00
   * @param value input number or string, that could potentially be formatted
   * @returns plain number without formatting
   * @todo consider handling for other formats, where the comma is used as the decimal separator. Will this cause issues?
   */
  getNumberWithoutCommas(value: number | string): number {
    if (!value) {
      return;
    }

    return Number(value.toString().replace(/,/g, ''));
  }
}
