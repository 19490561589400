import escapeStringRegexp from 'escape-string-regexp';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { FetchInstrumentation, FetchInstrumentationConfig } from '@opentelemetry/instrumentation-fetch';
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';
import { UserInteractionInstrumentationConfig } from '@opentelemetry/instrumentation-user-interaction/build/esm/types';
import {
  XMLHttpRequestInstrumentation,
  XMLHttpRequestInstrumentationConfig,
} from '@opentelemetry/instrumentation-xml-http-request';

import { SDK_OPTIONS } from './common';
import { OpenTelemetryModuleOptions } from './config';
import { WebVitalsInstrumentation } from './instrumentations';
import { MetricService } from './metrics';
import { TraceService } from './tracing';

class InvertedRegExp extends RegExp {
  [Symbol.match](param: string): RegExpMatchArray | null {
    const matches = this.exec(param);

    return matches ? null : matches;
  }
}

type UnregisterInstrumentationsCallback = () => void;

@Injectable()
export class NoopOpenTelemetryService {}

@Injectable()
export class OpenTelemetryService implements OnDestroy {
  private readonly unregisterInstrumentations: UnregisterInstrumentationsCallback;

  constructor(
    @Inject(SDK_OPTIONS) private readonly options: OpenTelemetryModuleOptions,
    private readonly traceService: TraceService,
    private readonly metricService: MetricService,
  ) {
    this.unregisterInstrumentations = this.registerInstrumentations();
  }

  private registerInstrumentations(): () => void {
    const urlToRegExp = (url: string): string => {
      return escapeStringRegexp(url).replaceAll(/\\\*/g, '.*');
    };
    const propagate = this.options.tracing?.propagate?.map(url => new RegExp(`^${urlToRegExp(url)}`, 'i')) ?? [];
    const whitelist =
      this.options.tracing?.whitelist?.map(url => new InvertedRegExp(`^${urlToRegExp(url)}`, 'i')) ?? [];

    const xhrFetchOptions: FetchInstrumentationConfig | XMLHttpRequestInstrumentationConfig = {
      clearTimingResources: true,
      enabled: this.options.tracing?.enabled,
      propagateTraceHeaderCorsUrls: propagate,
      ignoreUrls: whitelist,
    };

    const userInteractionOptions: UserInteractionInstrumentationConfig = {
      eventNames: ['click'],
    };

    return registerInstrumentations({
      instrumentations: [
        new UserInteractionInstrumentation(userInteractionOptions),
        new XMLHttpRequestInstrumentation(xhrFetchOptions as XMLHttpRequestInstrumentationConfig),
        new FetchInstrumentation(xhrFetchOptions as FetchInstrumentationConfig),
        new WebVitalsInstrumentation(),
      ],
      meterProvider: this.metricService.getMeterProvider(),
      tracerProvider: this.traceService.getTracerProvider(),
    });
  }

  ngOnDestroy(): void {
    this.unregisterInstrumentations();
  }
}
