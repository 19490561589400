import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { InputComponent, InputDensity } from '../../../input';

@Component({
  selector: 'supy-counter-input',
  templateUrl: './counter-input.component.html',
  styleUrls: ['./counter-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CounterInputComponent implements ControlValueAccessor {
  @ViewChild(InputComponent, { static: false }) readonly input: InputComponent<number>;

  @Input() @HostBinding('attr.name') readonly name: string;
  @Input() readonly min?: number;
  @Input() readonly max?: number;
  @Input() readonly step: number = 1;
  @Input() readonly placeholder?: string;
  @Input() readonly density: InputDensity = 'small';
  @Input() readonly textSelection: boolean;
  @Input() readonly precision: number;
  @Input() disabled: boolean;

  @Input() value: number;

  @Output() readonly focusOut = new EventEmitter<FocusEvent>();
  @Output() readonly valueChange = new EventEmitter<number>();

  get decreaseDisabled(): boolean {
    return this.disabled || (this.value !== undefined && this.value <= this.min);
  }

  get increaseDisabled(): boolean {
    return this.disabled || (this.value !== undefined && this.value >= this.max);
  }

  onChange: (value: number) => void;

  onTouched: () => void;

  touched = false;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Optional()
    @Inject(NgControl)
    private readonly control?: NgControl,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  increase(): void {
    if (this.max !== undefined && this.value >= this.max) {
      return;
    }

    this.onValueChange(Number(this.value) + Number(this.step));
  }

  decrease(): void {
    if (this.min !== undefined && this.value <= this.min) {
      return;
    }

    this.onValueChange(Number(this.value) - Number(this.step));
  }

  onValueChange(value: number): void {
    if (!this.disabled) {
      this.value = value;
      this.valueChange.emit(this.value);

      this.onChange?.(this.value);
    }
  }

  onFocusOut(e: FocusEvent): void {
    this.focusOut.emit(e);
    this.markAsTouched();
  }

  onInputKeydown(e: KeyboardEvent): void {
    if (e.key === 'ArrowUp') {
      this.increase();
    } else if (e.key === 'ArrowDown') {
      this.decrease();
    }
  }

  writeValue(value: number): void {
    this.value = value;
    this.cdr.markForCheck();
  }

  registerOnChange(onChange: (value: number) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
      this.emitTouchStatusChanged();
    }
  }

  focus(): void {
    this.input.focus();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    this.cdr.detectChanges();
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }
}
