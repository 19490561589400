import {
  catchError,
  concat,
  debounceTime,
  EMPTY,
  filter,
  first,
  from,
  interval,
  map,
  Observable,
  race,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import {
  HorizontalAlignment,
  IgxToastComponent,
  PositionSettings,
  VerticalAlignment,
} from '@infragistics/igniteui-angular';

import { CommonConfig, Destroyable, isInstanceOf } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

const UPDATE_CHECK_INTERVAL = 5 * 60 * 1000; // every 5 mins;

@Component({
  selector: 'supy-version-check',
  templateUrl: './version-check.component.html',
  styleUrls: ['./version-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionCheckComponent extends Destroyable implements OnInit {
  @ViewChild(IgxToastComponent, { static: true }) readonly toast: IgxToastComponent;

  readonly positionSettings: PositionSettings = {
    horizontalDirection: HorizontalAlignment.Center,
    verticalDirection: VerticalAlignment.Bottom,
  };

  get versionHash(): string | undefined {
    return this.config.versionHash;
  }

  private readonly config = inject<CommonConfig>(APP_CONFIG);
  private updatedVersionHash: string | undefined;

  constructor(
    private readonly appRef: ApplicationRef,
    private readonly cdr: ChangeDetectorRef,
    private readonly httpClient: HttpClient,
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    super();
  }

  ngOnInit(): void {
    this.checkUpdate();
  }

  checkUpdate(): void {
    const appTimeout = 30 * 1000; // 30 sec to wait for app to become stable;
    const appTimeout$ = interval(appTimeout).pipe(first());
    /*
     * Check for changes as soon as the application is stable
     * or after 30 seconds (whichever comes first).
     */
    const appIsStable$ = race(this.appRef.isStable.pipe(first(Boolean)), appTimeout$);
    const checkInterval$ = interval(UPDATE_CHECK_INTERVAL);
    const checkIntervalOnceAppIsStable$ = concat(appIsStable$, checkInterval$);

    // check for updates manually if no network calls done for defined period of time
    checkIntervalOnceAppIsStable$
      .pipe(
        takeUntil(this.destroyed$),
        filter(() => this.versionHash !== 'local'),
        switchMap(() => this.checkForUpdate()),
      )
      .subscribe(() => {
        this.openToast();
      });

    // check for updates on each route change
    this.router.events
      .pipe(
        filter(() => this.versionHash !== 'local'),
        filter(isInstanceOf(NavigationStart)),
        debounceTime(1000),
        takeUntil(this.destroyed$),
        switchMap(() => this.checkForUpdate()),
      )
      .subscribe(() => {
        this.openToast();
      });
  }

  openToast(): void {
    this.toast.open();
    this.cdr.markForCheck();
  }

  refreshApp(): void {
    localStorage.setItem('version', this.updatedVersionHash);
    this.document.location.href = `${this.document.location.origin}/refresh`;
  }

  private checkForUpdate(): Observable<unknown> {
    return from(import('yaml')).pipe(
      switchMap(yaml =>
        this.httpClient
          .get(`${location.origin}/assets/version.yaml?${Date.now()}`, {
            responseType: 'text',
          })
          .pipe(
            map(version => yaml.parse(version) as { hash: string }),
            catchError(() => EMPTY),
            map(({ hash }) => hash),
            filter(hash => Boolean(hash)),
            filter(hash => this.versionHash !== hash),
            tap(hash => {
              this.updatedVersionHash = hash;
            }),
          ),
      ),
    );
  }
}
