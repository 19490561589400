export type UomId = string;

export enum UomType {
  Base = 'Base',
  Product = 'Product',
}

export enum UomCategory {
  Volume = 'Volume',
  Weight = 'Weight',
  Length = 'Length',
  Quantity = 'Quantity',
}
export class Uom {
  readonly id: UomId;
  readonly name: string;
  readonly type?: UomType;
  readonly category?: UomCategory;
  readonly conversionToAtom: number;
  readonly isPiece?: boolean;
  readonly itemDefault?: boolean;

  constructor(uom: Uom) {
    Object.assign(this, uom);
  }

  static default(): Uom {
    return { id: crypto.randomUUID(), name: '', conversionToAtom: 1 };
  }
}
