/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { diag, createNoopMeter } from '@opentelemetry/api';
import { Resource } from '@opentelemetry/resources';
import { MeterProviderSharedState } from './state/MeterProviderSharedState';
import { MetricCollector } from './state/MetricCollector';
/**
 * @param mergeWithDefaults
 * @param providedResource
 */
function prepareResource(mergeWithDefaults, providedResource) {
  var resource = providedResource !== null && providedResource !== void 0 ? providedResource : Resource.empty();
  if (mergeWithDefaults) {
    return Resource.default().merge(resource);
  }
  return resource;
}
/**
 * This class implements the {@link MeterProvider} interface.
 */
var MeterProvider = /** @class */function () {
  function MeterProvider(options) {
    var e_1, _a, e_2, _b;
    var _c;
    this._shutdown = false;
    this._sharedState = new MeterProviderSharedState(prepareResource((_c = options === null || options === void 0 ? void 0 : options.mergeResourceWithDefaults) !== null && _c !== void 0 ? _c : true, options === null || options === void 0 ? void 0 : options.resource));
    if ((options === null || options === void 0 ? void 0 : options.views) != null && options.views.length > 0) {
      try {
        for (var _d = __values(options.views), _e = _d.next(); !_e.done; _e = _d.next()) {
          var view = _e.value;
          this._sharedState.viewRegistry.addView(view);
        }
      } catch (e_1_1) {
        e_1 = {
          error: e_1_1
        };
      } finally {
        try {
          if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
        } finally {
          if (e_1) throw e_1.error;
        }
      }
    }
    if ((options === null || options === void 0 ? void 0 : options.readers) != null && options.readers.length > 0) {
      try {
        for (var _f = __values(options.readers), _g = _f.next(); !_g.done; _g = _f.next()) {
          var metricReader = _g.value;
          this.addMetricReader(metricReader);
        }
      } catch (e_2_1) {
        e_2 = {
          error: e_2_1
        };
      } finally {
        try {
          if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
        } finally {
          if (e_2) throw e_2.error;
        }
      }
    }
  }
  /**
   * Get a meter with the configuration of the MeterProvider.
   */
  MeterProvider.prototype.getMeter = function (name, version, options) {
    if (version === void 0) {
      version = '';
    }
    if (options === void 0) {
      options = {};
    }
    // https://github.com/open-telemetry/opentelemetry-specification/blob/main/specification/metrics/sdk.md#meter-creation
    if (this._shutdown) {
      diag.warn('A shutdown MeterProvider cannot provide a Meter');
      return createNoopMeter();
    }
    return this._sharedState.getMeterSharedState({
      name: name,
      version: version,
      schemaUrl: options.schemaUrl
    }).meter;
  };
  /**
   * Register a {@link MetricReader} to the meter provider. After the
   * registration, the MetricReader can start metrics collection.
   *
   * <p> NOTE: {@link MetricReader} instances MUST be added before creating any instruments.
   * A {@link MetricReader} instance registered later may receive no or incomplete metric data.
   *
   * @param metricReader the metric reader to be registered.
   *
   * @deprecated This method will be removed in SDK 2.0. Please use
   * {@link MeterProviderOptions.readers} via the {@link MeterProvider} constructor instead
   */
  MeterProvider.prototype.addMetricReader = function (metricReader) {
    var collector = new MetricCollector(this._sharedState, metricReader);
    metricReader.setMetricProducer(collector);
    this._sharedState.metricCollectors.push(collector);
  };
  /**
   * Shut down the MeterProvider and all registered
   * MetricReaders.
   *
   * Returns a promise which is resolved when all flushes are complete.
   */
  MeterProvider.prototype.shutdown = function (options) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (this._shutdown) {
              diag.warn('shutdown may only be called once per MeterProvider');
              return [2 /*return*/];
            }
            this._shutdown = true;
            return [4 /*yield*/, Promise.all(this._sharedState.metricCollectors.map(function (collector) {
              return collector.shutdown(options);
            }))];
          case 1:
            _a.sent();
            return [2 /*return*/];
        }
      });
    });
  };
  /**
   * Notifies all registered MetricReaders to flush any buffered data.
   *
   * Returns a promise which is resolved when all flushes are complete.
   */
  MeterProvider.prototype.forceFlush = function (options) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            // do not flush after shutdown
            if (this._shutdown) {
              diag.warn('invalid attempt to force flush after MeterProvider shutdown');
              return [2 /*return*/];
            }
            return [4 /*yield*/, Promise.all(this._sharedState.metricCollectors.map(function (collector) {
              return collector.forceFlush(options);
            }))];
          case 1:
            _a.sent();
            return [2 /*return*/];
        }
      });
    });
  };
  return MeterProvider;
}();
export { MeterProvider };
