// web-vitals-autoinstrumentation.js

import { Metric, onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals';
import { Context, context, SpanKind, trace } from '@opentelemetry/api';
import { hrTime } from '@opentelemetry/core';
import { InstrumentationBase, InstrumentationConfig } from '@opentelemetry/instrumentation';

export class WebVitalsInstrumentation extends InstrumentationBase {
  constructor(config?: InstrumentationConfig) {
    super('web-vitals-instrumentation', '1.0.0', { ...config });
  }

  onReport(metric: Metric, parentSpanContext: Context) {
    const webVitalsSpan = this.tracer.startSpan(
      metric.name,
      {
        startTime: hrTime(),
        kind: SpanKind.CLIENT,
      },
      parentSpanContext,
    );

    webVitalsSpan.setAttributes({
      ['web_vital.name']: metric.name,
      ['web_vital.id']: metric.id,
      ['web_vital.navigationType']: metric.navigationType,
      ['web_vital.delta']: metric.delta,
      ['web_vital.rating']: metric.rating,
      ['web_vital.value']: metric.value,
      ['web_vital.entries']: JSON.stringify(metric.entries),
    });

    webVitalsSpan.end();
  }

  override enable() {
    const parentSpan = this.tracer.startSpan('web-vitals');
    const ctx = trace.setSpan(context.active(), parentSpan);

    onFID(metric => this.onReport(metric, ctx));
    onINP(metric => this.onReport(metric, ctx));
    onTTFB(metric => this.onReport(metric, ctx));
    onFCP(metric => this.onReport(metric, ctx));
    onCLS(metric => this.onReport(metric, ctx));
    onLCP(metric => this.onReport(metric, ctx));

    parentSpan.end();
  }

  protected override init(): void {
    return;
  }
}
