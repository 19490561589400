import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxAvatarModule } from '@infragistics/igniteui-angular';

import { ImageModule } from '../image';
import { AvatarComponent } from './components';

@NgModule({
  declarations: [AvatarComponent],
  imports: [CommonModule, IgxAvatarModule, ImageModule],
  exports: [AvatarComponent],
})
export class AvatarModule {}
