import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbsoluteScrollStrategy,
  GlobalPositionStrategy,
  IDialogCancellableEventArgs,
  IDialogEventArgs,
  IgxDialogComponent,
  IgxOverlayOutletDirective,
  OverlaySettings,
  PositionSettings,
} from '@infragistics/igniteui-angular';

import { Destroyable } from '@supy/common';

import { IDialogComponent } from '../../interfaces';
import { DialogPosition, dialogPositionSettings, DialogState, DialogStretch } from './dialog-settings';

export type DialogWidth = `${number}${'vw' | '%' | 'rem'}` | 'auto';
export type DialogHeight = `${number}${'vh' | '%' | 'rem'}` | 'auto';
export type DialogOffset = `${number}${'vh' | 'vw' | '%' | 'rem'}` | 'auto';

@Component({
  selector: 'supy-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent extends Destroyable implements AfterViewInit, IDialogComponent, OnChanges {
  @Input() readonly position: DialogPosition;
  @Input() readonly stretch?: DialogStretch;
  @Input() readonly initialState?: DialogState;
  @Input() readonly overlaySettings?: OverlaySettings;
  @Input() readonly width?: DialogWidth;
  @Input() readonly height?: DialogHeight;
  @Input() readonly offsetTop?: DialogOffset;
  @Input() readonly overlayClass?: string;

  @Input() readonly closeOnOutsideClick: boolean = false;
  @Input() readonly closeOnEscape: boolean = true;

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();

  @ViewChild(IgxOverlayOutletDirective, { static: true })
  readonly outlet: IgxOverlayOutletDirective;

  @ViewChild(IgxDialogComponent, { static: true })
  readonly dialog: IgxDialogComponent;

  get state(): DialogState {
    return this.dialog.state as DialogState;
  }

  get #overlaySettings(): OverlaySettings {
    const position: PositionSettings = dialogPositionSettings[this.position];

    return {
      modal: false,
      closeOnOutsideClick: this.closeOnOutsideClick,
      closeOnEscape: this.closeOnEscape,
      outlet: this.outlet,
      scrollStrategy: new AbsoluteScrollStrategy(),
      positionStrategy: new GlobalPositionStrategy(position),
      ...this.overlaySettings,
    };
  }

  constructor(
    private readonly hostElement: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.width && !changes.width.firstChange) || changes.offsetTop) {
      this.setDialogSizes();
    }

    if (changes.overlayClass) {
      this.setOverlayClass();
    }
  }

  ngAfterViewInit(): void {
    if (this.initialState === 'open') {
      this.openDialog();
    }
    this.setDialogSizes();
    this.setOverlayClass();
  }

  setDialogSizes(): void {
    const { nativeElement } = this.hostElement;
    const dialogWindow = nativeElement.querySelector('.igx-dialog__window');

    if (this.width) {
      this.renderer.setStyle(dialogWindow, 'width', this.width);
    }

    if (this.height) {
      this.renderer.setStyle(dialogWindow, 'height', this.height);
    }

    if (this.offsetTop) {
      const overlay = this.outlet.nativeElement.querySelector('.igx-overlay__wrapper');

      if (overlay) {
        this.renderer.setStyle(overlay, 'top', this.offsetTop);
      }
    }
  }

  private setOverlayClass(): void {
    if (this.overlayClass) {
      const overlay = this.outlet.nativeElement.querySelector('.igx-overlay__wrapper');

      if (overlay) {
        this.renderer.addClass(overlay, this.overlayClass);
      }
    }
  }

  openDialog(): void {
    this.dialog.open(this.#overlaySettings);
  }

  closeDialog(): void {
    this.dialog.close();
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }
}
