<image-cropper
  [style.display]="showCropper ? 'flex' : 'none'"
  class="supy-image-cropper"
  [imageChangedEvent]="imageChangedEvent"
  [hideResizeSquares]="true"
  [transform]="transform"
  (imageCropped)="onImageCropped($event)"
  [roundCropper]="true"
  (imageLoaded)="onImageLoaded()"
  (loadImageFailed)="onLoadImageFail()"
  [imageURL]="imageURL"
  [imageBase64]="imageBase64"
  [imageFile]="imageBlob"
  format="webp"
></image-cropper>
<supy-loader *ngIf="!showCropper" color="primary" size="large"></supy-loader>
<supy-slider *ngIf="showCropper" [max]="200" (valueChange)="onValueChange($event)" [initialValue]="100"></supy-slider>
